@import "../../assets/styles/fonts";
@import "../../assets/styles/mainVariables";
@import "../../assets/styles/mixins";

.footer-wrapper {
  background-color: $darkColor;
  overflow: hidden;
  width: 100%;

  .top-side {
    padding: 40px $mainPadding 0 $mainPadding;
    display: flex;
    justify-content: space-between;
    .fc_icon{
      svg{
        path{
          fill: white;
        }
      }
      &:hover{
       svg{
         path{
           fill:$brandColor;
         }
       }
      }
    }
  }

  .col {
    width: 20%;
    color: white;
    display: flex;
    flex-direction: column;

    &.col:first-child {
      width: 40%;
    }

    .logo-wrapper {
      margin-bottom: 30px;

      svg, img {
        height: 70px;
        width: 70px;
      }
    }

    .footer-description {
      font-size: 16px;
      width: 70%;
      letter-spacing: 1px;
    }

    .col-title {
      font-size: 18px;
      font-family: Medium;
      margin-bottom: 24px;
    }

    .nav-item {
      margin-bottom: 16px;

      &:hover {
        color: $brandColor;
      }

      &:active {
        color: $brandColor;
      }
    }

    .social, .info, .apps-wrapper {
      display: flex;
      align-items: center;
      color: $darkColor;
      font-size: 16px;
    }

    .info-item {
      margin-right: 30px;
      display: flex;
      align-items: center;
      letter-spacing: 0.4px;
      transition: all 0.2s;
      margin-bottom: 16px;

      svg {
        margin-right: 10px;

        path {
          fill: white;
          transition: all 0.2s;
        }
      }

      &:hover {
        color: $brandColor;

        svg {
          path {
            fill: $brandColor;
          }
        }
      }
    }
    .apps-wrapper {
      margin-top: 10px;
      display: flex;

      a {
        svg, img {
          height: 36px;
          width: 120px;
        }
      }

      .google-play {
        margin-right: 10px;
      }
    }

    .social {
      margin-left: -5px;

      a {
        margin-right: 8px;

        svg, img {
          width: 32px;
          height: 32px;

        }

        &:hover {
          svg {
            path {
              fill: $brandColor;
            }
          }
        }
      }
    }

    .apps-wrapper {
      margin-top: 16px;

      svg {
        width: 120px;
      }
    }
  }

  .bottom-side {
    padding: 16px $mainPadding;
    border-top: 1px solid $greyBorderColor;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
    color: white;

    .copyright {
      font-size: 14px;
      letter-spacing: 1px;
    }

    .developed-by {
      display: flex;
      align-items: center;

      span {
        margin-right: 20px;
        font-size: 12px;
        letter-spacing: 0.4px;
      }

      a {
        display: flex;
        align-items: center;
      }

      svg {
        height: 22px;
        width: 80px;
      }
    }
  }

  @media all and (max-width: 1050px) {
    flex-wrap: wrap;
    .top-side {
      flex-wrap: wrap;
    }

    .col {
      width: calc(100% / 3);

      &.col:first-child {
        width: 100%;
        margin-bottom: 20px;
        .footer-description{
          margin-top: 4px;
        }
      }

      .logo-wrapper {
        margin-bottom: 0;
        margin-right: 20px;

        svg, img {
          height: 60px;
          width: 60px;
        }
      }

      .footer-description {
        font-size: 14px;
        width: calc(80% - 90px);
      }

      .col-title {
        font-size: 16px;
        margin-bottom: 20px;
      }

      .nav-item {
        margin-bottom: 10px;
      }

      .social, .info, .apps-wrapper {
        font-size: 14px;
      }

      .social {
        a {
          margin-right: 8px;

          svg, img {
            width: 24px;
            height: 24px;
          }
        }
      }
    }

    .bottom-side {
      margin-top: 30px;

      .copyright {
        font-size: 12px;
      }

      .developed-by {
        svg {
          height: 26px;
          width: 80px;
        }
      }
    }
  }

  @media all and (max-width: 600px) {
    .top-side {
      width: 80%;
      margin: auto;
    }
    .col {
      width: 46%;

      &.col:last-child {
        width: 100%;
        margin-top: 20px;
        flex-wrap: wrap;

        div {
          //width: 50%;
        }

        .col-title {
          order: 1;
          width: 50%;
        }

        .dropdown-menu-item {
          order: 2;
          margin-bottom: -50px;
        }

        .social {
          order: 3;
          width: 50%;
        }
      }

      .logo-wrapper {
        margin-bottom: 0;
        margin-right: 20px;

        svg, img {
          height: 50px;
          width: 50px;
        }
      }

      .footer-description {
        font-size: 14px;
        width: 100%;
      }

      .col-title {
        font-size: 14px;
        margin-bottom: 16px;
      }

      .nav-item {
        margin-bottom: 8px;
      }

      .social, .info, .apps-wrapper {
        font-size: 12px;
      }

      .social {
        a {
          margin-right: 8px;

          svg, img {
            width: 36px;
            height: 36px;
          }
        }
      }
    }
    .bottom-side {
      flex-direction: column;

      .copyright {
        margin-bottom: 10px;
        text-align: center;
      }
    }
  }

  @media all and (max-width: 400px) {
    .top-side {
      width: 90%;
    }
    .col {
      width: 46%;

      .logo-wrapper {
        margin-right: 10px;

        svg, img {
          height: 40px;
          width: 40px;
        }
      }

      .footer-description {
        font-size: 12px;
      }

      .col-title {
        font-size: 14px;
        margin-bottom: 10px;
      }

      .info-item {
        font-size: 12px;
      }

      .nav-item {
        margin-bottom: 8px;
      }

      .social, .info, .apps-wrapper {
        font-size: 12px;
      }

      .social {
        a {
          margin-right: 8px;

          svg, img {
            width: 26px;
            height: 26px;
          }
        }
      }
    }
  }
}

.languages-dropdown {
  width: 82px;

  .language-dropdown-item {
    .ant-dropdown-menu-title-content {
      display: flex;
      align-items: center;
      text-transform: uppercase;
    }

    img {
      width: 20px;
      height: 14px;
      margin-right: 8px;
    }
  }
}

.dropdown-menu-item {
  text-transform: uppercase;
  font-size: 14px;
  font-family: Medium;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  margin-top: 10px;


  div {
    position: relative;
    top: 1px;
  }

  img {
    width: 24px;
    height: 20px;
    margin-right: 10px;
    border-radius: 1px;
  }

  svg {
    margin-top: -2px;

    polygon {
      fill: white;
    }
  }
}
