@import "../../assets/styles/mainVariables";
@import "../../assets/styles/mixins";
@import "../../assets/styles/fonts";

.ant-modal{
  width: 650px !important;
  height: 390px;
  transform-origin: unset !important;
  animation-duration: unset !important;
}
.ant-modal-content {
  border-radius: 4px;
  height: 100%;
  transform-origin: unset !important;
  animation-duration: unset !important;

  .ant-modal-close {
    top: 6px;
    right: 2px;

    .ant-modal-close-x {
      color: white;
      font-size: 22px;
    }
  }

  .ant-modal-header {
    height: 68px;
    display: flex;
    align-items: center;
    background-color:$brandColor;
    border-radius: 4px 4px 0 0;

    .ant-modal-title {
      color: white;
      font-size: 17px;
    }
  }
}
.ant-modal-footer{
  border: 0;
  .ant-btn {
    &.ant-btn-default {
      display: none !important;
    }
    &.ant-btn-primary{
      position: absolute;
      bottom: 30px;
      right: 20px;
      width: 120px;
      height: 39px;
      border-radius: 4px;
      font-size: 16px;
      border-color:$brandColor !important;
      background:$brandColor!important;
    }
  }
}
.bottomBlock{
  display: flex;
  justify-content: space-between;
  width: 100%;
  .custom-input-group{
    width: 44%;
  }
}