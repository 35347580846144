
.progress-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-right: 40px;

  .start-index, .end-index {
    color: black;
    font-weight: 600;
    font-size: 18px;
  }

  .progress-bar {
    width: calc(100% - 80px);
    position: relative;
    border-radius: 5px;
    height: 5px;
    background: #d8d8d8;
    margin: auto;

    .stroke {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 5px;
      z-index: 10;
      background: #f9a127;
      display: block;
      border-radius: 5px;
      will-change: width;

      &.animate {
        animation-duration: 5s;
        animation-fill-mode: both;
        animation-name: progress;
      }
    }
  }

  span {
    color: black;

  }
}


@keyframes progress {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}


/*.progress-wrapper {
  //display: flex;
  ////flex-direction: column;
  //justify-content: center;
  //position: absolute;
  //top: 50%;
  //left: 4%;
  //height: 66%;
  //transform: translateY(-50%);
  //z-index: 1;

  .progress-bar {
    //position: relative;
    //top: 0;

    .stroke-wrapper {
      //background-color: #B9BECF;
      //position: absolute;
      //top: 54px;
      //bottom: 54px;
      //width: 4px;
      //border-radius: 5px;
    }

    .stroke {
      //width: 4px;
      //background: black;
      //display: block;
      //border-radius: 5px;
      //will-change: height;


      &.animate {
        animation-duration: 3.2s;
        animation-fill-mode: both;
        animation-name: progress;
      }
    }


    @keyframes progress {
      0% {
        height: 0;
      }
      100% {
        height: 100%;
      }
    }
  }

  .start-index, .end-index {
    position: absolute;
    color: black;
    //transform: rotate(-90deg);
    font-family: Bold;
    font-size: 26px;
  }

  .start-index {
    top: 0;
  }

  .end-index {
    bottom: 0;
  }

  @media all and (max-width: 1400px) {
    .progress-bar {

      .stroke-wrapper {
        top: 42px;
        bottom: 42px;
      }
    }
    .start-index, .end-index {
      font-size: 22px;
    }
  }
  @media all and (max-width: 1000px) {
    .progress-bar {

      .stroke-wrapper {
        top: 32px;
        bottom: 32px;
      }
    }
    .start-index, .end-index {
      font-size: 18px;
    }
  }

  @media all and (max-width: 800px) {
    .progress-bar {

      .stroke-wrapper {
        top: 24px;
        bottom: 24px;
      }
    }
    .start-index, .end-index {
      font-size: 15px;
      height: 18px;
    }
  }

  @media all and (max-width: 700px) {
    display: none;
    .progress-bar {

      .stroke-wrapper {
        top: 18px;
        bottom: 18px;
        width: 3px;

        .stroke {
          width: 3px;
        }
      }
    }
    .start-index, .end-index {

      font-size: 12px;
      height: 15px;

    }
  }
  @media all and (max-width: 600px) {
    .progress-bar {

      .stroke-wrapper {
        top: 18px;
        bottom: 18px;
        width: 3px;

        .stroke {
          width: 3px;
        }
      }
    }
    .start-index, .end-index {
      font-size: 10px;
      height: 13px;
    }
  }
}*/

