@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/mixins";
@import "../../../assets/styles/fonts";

.services-wrapper {
  width: 100%;
  padding: 180px $mainPadding 80px $mainPadding;

  .service-title {
    font-size: 40px;
    font-family: Bold;
    position: relative;
    display: inline-block;
    padding-bottom: 10px;

    &:after {
      content: '';
      width: 45%;
      height: 3px;
      background-color: $brandColor;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  .services-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 60px;

    //.link{
    //  width: 100%;
    //}
    .service-item {
      width: 23%;
      //max-width: 300px;
      border-radius: 4px;
      background: $mainBgColor;
      border-bottom: 3px solid rgba(236, 123, 26, 1);
      padding: 30px 30px 0 30px;
      cursor: pointer;
      transition: all .25s;

      .link{
        width: 100%;
        .service-item-title {
          width: 100%;
          font-size: 21px;
          line-height: 36px;
          //font-family: Medium;
          color: black;
          margin-bottom: 24px;
          height: 68px;
          overflow: hidden;
        }

        .service-img-wrapper {
          width: 100%;
          display: flex;
          justify-content: space-between;
          //position: relative;
          .dots-icon {
            visibility: hidden;
            margin-left: -26px;
          }

          .service-item-icon {
            height: 60px;
            margin-bottom: 10px;
          }
        }

      }
      &:hover {
        background: rgb(252, 169, 42);
        background: linear-gradient(180deg, rgba(252, 169, 42, 1) 0%, rgba(236, 123, 26, 1) 100%);
        //border-bottom: 0;

        .service-item-title {
          color: white;
        }

        .service-img-wrapper {

          .dots-icon {
            visibility: visible;
          }
        }
      }
    }
  }

  @media all and (max-width: 1500px) {
    .services-content {

      .service-item {
        padding: 20px 20px 0 20px;

        .service-item-title {
          width: 100%;
          font-size: 22px;
          line-height: 32px;
          margin-bottom: 20px;
        }

        .service-img-wrapper {
          .dots-icon {
            margin-left: -16px;
          }

          .service-item-icon {
            height: 60px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  @media all and (max-width: 1050px) {
    .services-content {

      .service-item {
        padding: 10px 10px 0 10px;

        .service-item-title {
          width: 100%;
          font-size: 18px;
          height: 52px;
          line-height: 26px;
        }

        .service-img-wrapper {
          width: 100%;
          display: flex;
          justify-content: space-between;

          .dots-icon {
            visibility: hidden;
            margin-left: -16px;
            height: 60px;
          }

          .service-item-icon {
            height: 44px;
            margin-bottom: 0;
          }
        }

        &:hover {
          background-color: $brandColor;

          .service-item-title {
            color: white;
          }

          .service-img-wrapper {

            .dots-icon {
              visibility: visible;
            }
          }
        }
      }
    }
  }

  @media all and (max-width: 700px) {
    padding: 100px $mainPadding 40px $mainPadding;
    .service-title {
      font-size: 30px;
    }
    .services-content {
      flex-wrap: wrap;

      .service-item {
        width: 48%;
        margin-bottom: 20px;

        .service-item-title {
          width: 100%;
          font-size: 18px;
          height: 52px;
          line-height: 26px;
        }

        .service-img-wrapper {

          .dots-icon {
            height: 70px;
          }

          .service-item-icon {
            height: 50px;
            margin-bottom: 0;
          }
        }
      }
    }
  }

  @media all and (max-width: 400px) {
    .services-content {
      flex-wrap: wrap;

      .service-item {
        width: 49%;
        margin-bottom: 10px;

        .service-item-title {
          width: 100%;
          font-size: 16px;
          height: 52px;
          line-height: 26px;
        }

        .service-img-wrapper {

          .dots-icon {
            height: 60px;
          }

          .service-item-icon {
            height: 40px;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
