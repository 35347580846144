@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/mixins";
@import "../../../assets/styles/fonts";

.main-wrapper {
  position: relative;
  top: 80px;
  width: 100%;
  height: calc(100vh - 80px);
  background-color: #fffcf6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 $mainPadding;

  .main-content {
    width: 48%;

    .main-title {
      font-family: Bold;
      font-size: 54px;
      font-weight: bold;
      letter-spacing: 0.4px;
      overflow: hidden;
      line-height: 70px;
      height: 136px;
      color: $darkColor;
      margin-bottom: 50px;
    }

    .main-description {
      width: 100%;
      color: $darkColor;
      //@include font(Regular, 20);
      font-size: 20px;
      line-height: 36px;
      height: 102px;
      overflow: hidden;
    }

    .download-btn {
      height: 50px;
      width: 220px;
      border-radius: 4px;
      background-color: $brandColor;
      color: white;
      @include flex-center;
      margin-top: 50px;
      letter-spacing: 1px;
      font-size: 15px;
    }
  }

  .main-img {
    width: 45%;

    img {
      position: relative;
      z-index: 1;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .box {
      position: absolute;
      top: 0;
      right: 0;
      width: 30%;
      height: 76%;
      z-index: 0;
      background-color: $brandSecColor;
    }
  }

  @media all and (max-width: 1500px) {
    .main-content {
      width: 46%;
    }
    .main-img {
      .box {
        width: 30%;
        height: 70%;
      }
    }
  }

  @media all and (max-width: 1200px) {
    .main-content {
      .main-title {
        font-size: 42px;
        line-height: 60px;
        height: 114px;
        margin-bottom: 30px;
      }

      .main-description {
        font-size: 18px;
        line-height: 32px;
        height: 122px;
      }

      .download-btn {
        height: 44px;
        width: 190px;
        margin-top: 30px;
      }
    }
    .main-img {
      .box {
        //width: 30%;
        //height: 70%;
      }
    }
  }

  @media all and (max-width: 1050px) {
   top: 46px;
    flex-direction: column;

    .main-content {
      width: 100%;
      margin-bottom: 30px;
      .main-title {
        font-size: 38px;
        line-height: 40px;
        height: 40px;
        margin-bottom: 30px;
        margin-top: 30px;
      }

      .main-description {
        font-size: 16px;
        line-height: 24px;
        height: 46px;
      }

      .download-btn {
        height: 44px;
        width: 190px;
        margin-top: 30px;
      }
    }
    .main-img {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding: 40px;

      img{
        width: 80%;
      }
      .box {
        top: unset;
        bottom: 50px;
        width: 35%;
        height: 68%;
      }
    }

  }

  @media all and (max-width: 700px) {
    .main-content {
      margin-bottom: 50px;
      .main-title {
        font-size: 30px;
        line-height: 30px;
        height: 30px;
        margin-bottom: 20px;
        margin-top: 20px;
      }

      .main-description {
        font-size: 14px;
        line-height: 20px;
        height: 62px;
      }

      .download-btn {
        height: 36px;
        width: 160px;
        margin-top: 30px;
      }
    }
    .main-img {
      width: 100%;
      padding: 20px;

      img{
        width: 80%;
      }
      .box {
        top: unset;
        bottom: 20px;
        width: 35%;
        height: 55%;
      }
    }
  }

}
