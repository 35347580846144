@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/mixins";
@import "../../../assets/styles/fonts";

.info-wrapper {
  width: 100%;
  padding: 0 $mainPadding;
  background-color: $brandSecColor;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .left-part {
    width: 46%;

    img {
      width: 80%;
    }
  }

  .right-part {
    width: 46%;
    padding: 50px 0;

    .info-title {
      font-size: 36px;
      letter-spacing: 1px;
      font-family: Bold;
      overflow: hidden;
      line-height: 46px;
      height: 90px;
    }

    .info-description {
      width: 100%;
      font-size: 16px;
      letter-spacing: 0.5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 20px 0;
    }
    .fortuneBtn{
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      color: white;
      height: 45px;
      width: 200px;
      font-size: 14px;
      font-family: Medium;
      letter-spacing: 0.4px;
      background-color: var(--brandColor);
      border-radius: 4px;
    }
  }

  @media all and (max-width: 1050px) {
    flex-direction: column-reverse;
    .left-part {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .right-part {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .info-title {
        width: 80%;
        text-align: center;
      }
      .info-description {
        text-align: center;
      }
    }
  }

  @media all and (max-width: 700px) {
    .right-part {

      .info-title {
        width: 100%;
        font-size: 30px;
        line-height: 40px;
        height: 78px;
      }

      .info-description {
        font-size: 14px;
      }

      .apps-wrapper {
        a {
          svg, img {
            height: 40px;
            width: 140px;
          }
        }

        .google-play {
          margin-right: 0;
        }
      }
    }
  }
}