@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/mixins";
@import "../../../assets/styles/fonts";

.about-wrapper {
  padding: 180px $mainPadding;
  display: flex;
  justify-content: space-between;

  .left-side {
    width: 52%;
    position: relative;

    .image-wrapper {
      width: 100%;
      padding-top: 71.3%;
      position: relative;

      .about-img {
        position: absolute;
        top: 0;
        left: 10px;
        width: 100%;
        height: 100%;
        padding: 1px;
        border-radius: 8px;
        object-fit: contain;
      }
    }

    .img-description-wrapper {
      width: 80%;
      height: 120px;
      padding: 0 30px;
      border-radius: 4px;
      background-color: black;
      color: white;
      position: absolute;
      bottom: -60px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .img-title {
        font-size: 22px;
        font-family: Medium;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 10px;
        letter-spacing: 0.4px;
      }

      .img-description {
        letter-spacing: 1px;
      }
    }
  }

  .right-side {
    width: 42%;
    position: relative;

    .about-sub-title {
      color: #f9a164;
      font-size: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: Medium;
    }

    .about-title {
      width: 80%;
      font-size: 40px;
      color: black;
      font-family: Medium;
      margin-bottom: 14px;
      overflow: hidden;
      height: 60px;
    }

    .about-description {
      font-size: 18px;
      line-height: 30px;
      overflow: hidden;
      height: 300px;
      @media all and (min-width: 1780px) {
        height: 380px;
      }
    }

    .about-btn {
      visibility: hidden;
      height: 50px;
      width: 180px;
      border-radius: 4px;
      background-color: $brandColor;
      color: white;
      @include flex-center;
      position: absolute;
      bottom: -50px;
    }
  }

  @media all and (max-width: 1600px) {
    .right-side {

      .about-sub-title {
        font-size: 20px;
      }

      .about-title {
        width: 80%;
        font-size: 34px;
        height: 60px;
      }

      .about-description {
        font-size: 16px;
        line-height: 26px;
      }
    }
  }
  @media all and (max-width: 1200px) {
    .right-side {

      .about-description {
        height: 204px;
      }
    }
  }

  @media all and (max-width: 1050px) {
    flex-direction: column-reverse;
    padding: 100px $mainPadding;
    .left-side {
      width: 100%;

    }
    .right-side {
      width: 100%;
      .about-btn{
        position: unset;
        margin: 40px 0;
      }
    }
  }

  @media all and (max-width: 600px) {
    .left-side {
      .img-description-wrapper {
        width: 90%;
        height: 100px;
        padding: 0 12px;
        bottom: -50px;

        .img-title {
          font-size: 18px;
        }

        .img-description {
          font-size: 12px;
        }
      }
    }
    .right-side {
      width: 100%;
      .about-sub-title {
        font-size: 18px;
      }

      .about-title {
        width: 100%;
        font-size: 28px;
        height: 82px;
      }

      .about-description {
        font-size: 14px;
        line-height: 22px;
        height: 174px;
      }
      .about-btn{
        margin: 30px 0;
        height: 40px;
        width: 150px;
      }
    }
  }

}
