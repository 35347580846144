@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/mixins";
@import "../../../assets/styles/fonts";

.app-wrapper {
  padding: 100px $mainPadding;
  display: flex;
  justify-content: space-between;
  position: relative;

  .left-side {
    width: 50%;
    height: 100%;

    .slider-wrapper {
      width: 100%;
    }

    .slider-item {
      position: relative;
      width: 100%;
    }

    .sub-slide-title{
      color: #f9a164;
      font-size: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: Medium;
    }

    .slide-title {
      font-size: 52px;
      font-family: Medium;
      letter-spacing: 0.4px;
      overflow: hidden;
      line-height: 70px;
      height: 136px;
      color: $darkColor;
      margin-bottom: 50px;
    }

    .slide-description {
      width: 90%;
      color: $darkColor;
      font-size: 20px;
      line-height: 36px;
      height: 138px;
      overflow: hidden;
    }

    .slide-content {
      display: flex;
      justify-content: space-between;
      margin: 60px 0;

      .col {
        color: black;
        width: 46%;

        svg, img {
          width: 50px;
          height: 50px;
          margin-bottom: 10px;
        }

        .col-title {
          font-size: 22px;
          font-family: Medium;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .col-description {
          font-size: 16px;
          line-height: 30px;
        }
      }
    }

  }

  .right-side {
    position: relative;
    width: 48%;

    .app-bg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      width: 80%;
      z-index: -2;
    }

    .mockup-wrapper {
      width: 300px;
      padding-top: 100%;
      position: relative;
      left: 60%;
      transform: translateX(-50%);

      .image-proportion {
        position: absolute;
        top: 0;
        width: 100%;
      }

      .mockup {
        width: 100%;
        z-index: 2;
      }

      .navbar_bg {
        position: relative;
        width: calc(100% - 10px);
        z-index: -1;
        left: 5px;
        transform: translateY(-117%);

        img {
          width: 100%;
        }
      }

      .hover_nav {
        width: calc(100% - 10px);
        position: absolute;
        bottom: 34px;
        left: 5px;
        z-index: 0;

        #bg_1, #bg_2, #bg_3, #bg_4, #bg_5, {
          visibility: hidden;
          cursor: pointer;
        }

        #svg_1, #svg_2, #svg_3, #svg_4, #svg_5, {
          cursor: pointer;
        }

        #item_1:hover {
          #bg_1 {
            visibility: visible;
          }
        }

        #item_2:hover {
          #bg_2 {
            visibility: visible;
          }
        }

        #item_3:hover {
          #bg_3 {
            visibility: visible;
          }
        }

        #item_4:hover {
          #bg_4 {
            visibility: visible;
          }
        }

        #item_5:hover {
          #bg_5 {
            visibility: visible;
          }
        }
      }

      .home-bg {
        width: calc(100% - 10px);
        position: absolute;
        top: 9px;
        left: 5px;
        z-index: -2;
        border-radius: 8px 8px 0 0;
      }

      //.home-bg {
      //  width: calc(100% - 10px);
      //  position: absolute;
      //  bottom: 5px;
      //  left: 5px;
      //  z-index: -2;
      //  border-radius: 15px;
      //}
      //
      //.hover_nav {
      //  width: calc(100% - 10px);
      //  position: absolute;
      //  bottom: -48px;
      //  left: 5px;
      //  z-index: 0;
      //
      //  #bg_1, #bg_2, #bg_3, #bg_4, #bg_5, {
      //    visibility: hidden;
      //    cursor: pointer;
      //  }
      //
      //  #svg_1, #svg_2, #svg_3, #svg_4, #svg_5, {
      //    cursor: pointer;
      //  }
      //
      //  #item_1:hover {
      //    #bg_1 {
      //      visibility: visible;
      //    }
      //
      //    //#text_1{
      //    //    visibility: visible;
      //    //  }
      //  }
      //
      //  #item_2:hover {
      //    #bg_2 {
      //      visibility: visible;
      //    }
      //  }
      //
      //  #item_3:hover {
      //    #bg_3 {
      //      visibility: visible;
      //    }
      //  }
      //
      //  #item_4:hover {
      //    #bg_4 {
      //      visibility: visible;
      //    }
      //  }
      //
      //  #item_5:hover {
      //    #bg_5 {
      //      visibility: visible;
      //    }
      //  }
      //
      //  //
      //  //.rect {
      //  //  fill: #2cb76c;
      //  //}
      //
      //  &:nth-child(3) {
      //  }
      //}
      //
      //.navbar {
      //  width: calc(100% - 10px);
      //  position: absolute;
      //  bottom: 7px;
      //  left: 5px;
      //  z-index: -1;
      //  //display: flex;
      //  //justify-content: space-between;
      //  //overflow: hidden;
      //  height: 75px;
      //
      //  .nav-item {
      //    width: calc(20% - 1px);
      //    //height: 75px;
      //
      //    .select-nav {
      //      position: absolute;
      //      width: calc(20% - 1px);
      //      height: 70px;
      //      margin-bottom: 5px;
      //    }
      //
      //    span {
      //      position: absolute;
      //      font-size: 8px;
      //      color: black;
      //    }
      //
      //    &.mid {
      //      width: calc(20% + 6px);
      //      height: 75px;
      //
      //      .select-nav {
      //        //position: absolute;
      //        //top: 0;
      //        //width: calc(20% + 6px);
      //        //height: 75px;
      //        //margin-bottom: 0;
      //      }
      //    }
      //
      //    &:nth-child(2) {
      //      left: 20%;
      //      height: 70px;
      //    }
      //  }
      //}
    }
  }

  @media screen and (max-width: 1050px) {
    padding-top: 0;
    flex-direction: column;

    .left-side {
      width: 100%;
    }

    .right-side {
      width: 100%;
      //height: 630px;
      margin-top: 40px;
      //
      .app-bg {
        width: 60%;
        right: 50%;
        transform: translate(50%, -60%);
      }

      .mockup-wrapper {
        left: 50%;
      }
    }
  }

  @media all and (max-width: 700px) {

    .left-side {
      .slide-title {
        font-size: 40px;
        line-height: 56px;
        height: 108px;
        margin-bottom: 30px;
      }

      .slide-description {
        width: 100%;
        font-size: 18px;
        line-height: 30px;
        height: 86px;
      }

      .slide-content {
        margin: 40px 0;

        .col {
          width: 46%;

          svg, img {
            width: 40px;
            height: 40px;
            margin-bottom: 10px;
          }

          .col-title {
            font-size: 20px;
          }

          .col-description {
            font-size: 14px;
            line-height: 22px;
          }
        }
      }
    }

    .right-side {
      margin: 40px 0 80px 0;

      .app-bg {
        width: 90%;
        transform: translate(50%, -40%);
      }

      .mockup-wrapper {
        right: 50%;
      }
    }
  }

  @media all and (max-width: 550px) {

    .left-side {
      .slide-title {
        margin-bottom: 30px;
        font-size: 28px;
        line-height: 36px;
        height: 72px;
      }

      .slide-description {
        font-size: 16px;
        line-height: 26px;
        height: 80px;
      }

      .slide-content {
        margin: 30px 0;

        .col {
          width: 48%;

          svg, img {
            width: 30px;
            height: 30px;
            margin-bottom: 10px;
          }

          .col-title {
            font-size: 16px;
          }

          .col-description {
            font-size: 12px;
            line-height: 18px;
          }
        }
      }
    }

    .right-side {
      @media all and (max-width: 350px) {
        margin-bottom: 150px;
        .app-bg {
          transform: translate(50%, -20%);
        }
      }

      .app-bg {
        width: 100%;
      }

      .mockup-wrapper {
        width: 220px;

        .hover_nav {
          bottom: -2px;
        }

        .home-bg {
          width: calc(100% - 7px);
          left: 3px;
        }
        .navbar_bg {
          width: calc(100% - 7px);
          left: 3px;
        }
      }
    }
  }
}
