@import "../../assets/styles/mainVariables";
@import "../../assets/styles/mixins";
@import "../../assets/styles/fonts";

.header-wrapper {
  background-color: white;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;

  .service-center {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      position: fixed;
      bottom: 42px;
      right: calc(#{$mainPadding} / 2);
      @include flex-center;
      white-space: nowrap;
      color: white;
      background-color: $brandColor;
      padding: 0 15px;
      //font-family: Medium;
      line-height: 14px;
      font-weight: bold;
      letter-spacing: 0.4px;
      margin-top: 20px;

      .service-center-icon {
        width: 36px;
        height: 36px;

        path {
          fill: white;
        }
      }
      &::after {
        @include rings(3s, 0s);
      }

      &::before {
        @include rings(3s, 0.5s);
      }

      &:hover{
        background-color: $brandColor;
      }
    }
  .header-box-wrapper {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 $mainPadding;

    .header-logo {
      display: flex;
      align-items: center;
      margin-right: 0;

      path {
        fill: $darkColor;
      }

      .mobile-logo {
        width: 130px;
        height: 24px;

        path {
          fill: black;
        }
      }
    }

    .nav-bar {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: calc(100% - 100px);
      height: 100%;

      .nav-item {
        height: 100%;
        font-size: 14px;
        color: $darkColor;
        margin-left: 40px;
        cursor: pointer;
        font-family: Bold;
        letter-spacing: 0.4px;
        line-height: 16px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: flex;
        align-items: center;
        position: relative;
        transition: all 0.2s;

        &:hover {
          &:after {
            position: absolute;
            content: '';
            bottom: 0;
            width: 100%;
            height: 2px;
            background-color: $brandColor;
            border-radius: 2px 2px 0 0;
          }
        }
        &.active {
          &:after {
            position: absolute;
            content: '';
            bottom: 0;
            width: 100%;
            height: 2px;
            background-color: $brandColor;
            border-radius: 2px 2px 0 0;
          }
        }
      }

      .apps-wrapper {
        margin-left: 40px;
        height: 45px;
        display: flex;
        align-items: center;
        a {
          height: 36px;
          width: 120px;
          svg, img{
            height: 36px;
            width: 120px;
          }
        }
      }

    }

    @media all and (max-width: 1050px) {
      display: none;
    }
  }

  .mobile-nav-bar {
    display: none;
  }

  @media all and (max-width: 1050px) {
    padding: 0;
    .service-center{
      right: 24px;
      bottom: 100px;
    }
    .mobile-nav-bar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 46px;
      background-color: $brandColor;
      padding: 0 $mainPadding;

      .nav-bar-menu {
        $color: white;

        $height-icon: 16px;
        $width-line: 20px;
        $height-line: 2px;

        $transition-time: 0.2s;
        $rotation: 45deg;
        $translateY: ($height-icon / 2);
        $translateX: 0;

        width: $width-line;
        height: $height-icon;
        position: relative;
        display: block;
        cursor: pointer;

        .line {
          display: block;
          background: $color;
          width: $width-line;
          height: $height-line;
          position: absolute;
          left: 0;
          border-radius: 4px;
          transition: all $transition-time;
          -webkit-transition: all $transition-time;
          -moz-transition: all $transition-time;

          &.line-1 {
            top: 0;
          }

          &.line-2 {
            top: 50%;
            transform: translateY(-50%);
          }

          &.line-3 {
            top: 100%;
            transform: translateY(-100%);
          }
        }

        &:hover, &:focus {
          .line-1 {
            transform: translateY(-40%);
          }

          .line-3 {
            transform: translateY(-40%);
          }
        }

        &.active {
          .line-1 {
            transform: translateY($translateY) translateX($translateX) rotate($rotation);
            -webkit-transform: translateY($translateY) translateX($translateX) rotate($rotation);
            -moz-transform: translateY($translateY) translateX($translateX) rotate($rotation);
          }

          .line-2 {
            opacity: 0;
          }

          .line-3 {
            transform: translateY($translateY * -1) translateX($translateX) rotate($rotation * -1);
            -webkit-transform: translateY($translateY * -1) translateX($translateX) rotate($rotation * -1);
            -moz-transform: translateY($translateY * -1) translateX($translateX) rotate($rotation * -1);
          }
        }
      }

      .mobile-logo {
        //width: 100px;
        height: 40px;

        path, polygon {
        }
      }
    }
  }
}

