@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/fonts";

.custom-input-group {
  position: relative;
  margin-bottom: 24px;

  label {
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: $greyTextColor;
    margin-bottom: 8px;

    position: absolute;
    transform: translateY(8px);
    transition: color .2s ease,font-size .2s ease,transform .2s ease;
    pointer-events: none;
  }
  &.active{
    label{
      font-size: 12px;
      transform: translateY(-16px);
    }
  }

  .ant-select-selection-item {
    box-shadow: unset;
  }

  .ant-select-item-option-content {
    display: flex;
    align-items: center;
  }

  input, textarea, select {
    width: 100%;
    height: 42px;
    border: 0;
    border-bottom: 2px solid  #bfbfbf;
    padding: 8px 0;
    font-size: 16px;
    font-weight: 400;
    background: transparent;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    border-radius: 0;
    &:not(.ant-select-search__field):focus {
      outline: none;
      border-bottom-color:$brandColor;
    }

    &::placeholder{
      font-family: Regular;
    }

    &.resizable {
      resize: vertical;
      min-height: 100px;
    }

    &.invalid {
      border-bottom-color: $redColor;
    }
  }

  input[type="checkbox"] {
    height: 25px;
    width: 25px;
  }

  input::placeholder {
    color: $greyTextColor;
    font-size: 14px;
  }

  textarea {
    resize: none;
    height: 100px;
    padding: 12px;

    &::placeholder {
      font-family:Medium;
      color: $greyTextColor;
      font-size: 14px;
    }
  }

  .custom-select {
    position: relative;
    height: 42px;
    border-bottom: 2px solid  #bfbfbf;

    .ant-select-selection--multiple {
      min-height: 50px;

      .ant-select-selection__rendered {
        min-height: 50px;
        display: flex;
        align-items: center;

        li {
          margin-top: 2px;
          margin-bottom: 2px;
        }
      }
    }

    .ant-select-selection--single {
      height: 50px;
      display: flex;
      align-items: center;


      .ant-select-selection__rendered {
        width: 100%;
        overflow: hidden;
      }
    }

    .ant-select-selection-search {
      input {

        box-shadow: unset !important;
      }
    }

    &:focus {
      outline: none;
    }

  }

  .tree-select-wrapper {
    width: 100%;
    position: relative;
  }

  .ant-select-dropdown {
    .child-option {
      padding-left: 25px;
    }

    .parent-option {
      font-family:Bold;

      &:hover {
        background-color: transparent;
      }

      &.ant-select-item-option-disabled {
        color: unset;
        cursor: default;
      }
    }
  }

  .ant-select-arrow {
    top: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;

    .custom-suffix-icon {
      transform: none;
      width: 8px;
      height: 8px;
      transition: transform 0.2s;
      transform-origin: center;

      polygon {
        fill: black;
      }

      &.rotate {
        transform: rotate(180deg);
      }
    }
  }

  .visibility-icon {
    position: absolute;
    top: 11px;
    right: 14px;
    height: 18px;
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 18px;

    }

    i {
      font-size: 16px;
      user-select: none;
      -webkit-transition: color 0.3s ease;
      transition: color 0.3s ease;
      color: #10233c;
    }
  }

  &:focus {
    outline: none;
  }

  .error-message {
    color: $redColor;
    display: flex;
    align-items: center;
    font-size: 12px;
    position: absolute;

    &.show {
      visibility: visible;
      opacity: 1;
      height: 20px;
      padding-top: 10px;
    }

    &.hide {
      visibility: hidden;
      opacity: 0;
      height: 0;
      padding-top: 0;
    }
    @media screen and (max-width: 700px){
      font-size: 11px;
    }
  }
}

.select-options {
  width: 100%;
  max-height: 250px;
  overflow-x: hidden;
  overflow-y: auto;
  background: white;
  border-radius: 4px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  z-index: 50;


  .option-item {
    display: flex;
    align-items: center;
    padding: 5px 12px;
    overflow: hidden;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.65);
    font-weight: normal;
    line-height: 22px;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    -webkit-transition: background .3s ease;
    transition: background .3s ease;

    .clear-icon {
    }

    input {
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }

    &:hover {
      background-color: #e6f7ff;
    }

    &.checked {
      color: rgba(0, 0, 0, 0.65);
      font-weight: 600;
      background-color: #fafafa;
    }
  }
}

.clear-option {
  border-bottom: 1px solid $greyBorderColor;
  .ant-select-item-option-content{
    padding: 0;
    margin: 0;
    font-size: 12px;
  }

  span {
    display: flex;
    justify-content: space-between;
    align-items: center;
    svg{
      margin-left: -6px;
      width: 22px;
      height: 22px;
    }
  }
}