@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin max-size {
  width:100%;
  height: 100%;
}

@mixin font($family,$size) {
  font-family: $family;
  font-size: $size+px;
}

@mixin custom-scrollbar-x {
  &::-webkit-scrollbar {
    height: 3px;
    cursor: pointer;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb {
    background: #adadad;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #757575;
    cursor: pointer;
  }
}

@mixin custom-scrollbar-y {
  &::-webkit-scrollbar {
    width: 3px;
    cursor: pointer;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb {
    background: #adadad;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #757575;
    cursor: pointer;
  }
}

@mixin rings($duration, $delay) {
  opacity: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
  height: 100%;
  width: 100%;
  border: 20px solid rgba(255, 133, 11, 0.4);
  border-radius: 100%;
  animation-name: ripple;
  animation-duration: $duration;
  animation-delay: $delay;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(.65,0,.34,1);
  z-index: -1;

  @keyframes ripple {
    from {
      opacity: 1;
      transform: scale3d(0.75,0.75,1);
    }

    to {
      opacity: 0;
      transform: scale3d(1.5,1.5,1);
    }
  }
}
