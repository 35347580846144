@import "../../assets/styles/mainVariables";
@import "../../assets/styles/mixins";

.layout-wrapper {
  min-height: 100vh;
  width: 100%;
  position: relative;

  .main-content {
    .homepage-wrapper{
      section{
        //height: calc(100vh);
        //display: flex;
        //flex-direction: column;
        //justify-content: center;
      }
    }
  }

}

@media all and (max-width: 1050px) {
  .layout-wrapper {
    .main-content {
      .homepage-wrapper{
        section{
          height: unset;
        }
      }
    }
  }
}

@media all and (max-width: 600px) {
  .layout-wrapper {

    .main-content {
      //padding-top: 60px;
    }
  }
}
