@import "../../assets/styles/mainVariables";
@import "../../assets/styles/mixins";

.mobile-menu-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 46px;
  z-index: 1010;
  width: 100%;
  height: 100vh;
  overflow: auto;
  transition: all 0.35s cubic-bezier(.39, .575, .565, 1);
  visibility: hidden;
  opacity: 0;
  display: none;
  @media all and (max-width: 1050px) {
    display: block;
  }

  &.expand_on {
    visibility: visible;
    opacity: 1;
  }

  .mobile-menu-content {
    width: 350px;
    position: absolute;
    right: 0;
    top: 0;
    padding: 46px 20px 20px;
    z-index: 9002;
    height: 100%;
    background-color: white;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0;
    }

    .ant-dropdown-trigger {
      margin-top: 10px;
      color: $brandColor;
      white-space: nowrap;
      display: flex;
      align-items: center;

      .arrow-icon {
        width: 14px;
        height: 14px;
        transform: rotate(90deg);
        margin: 0 30px 0 8px;
      }
    }

    .nav-bar {
      width: 100%;
      display: flex;
      flex-direction: column;

      .nav-item {
        margin-top: 20px;
        color: #676769;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .phone-link {
      width: 100%;
    }

    .phone_btn{
      width: 80px;
      height: 80px;
      border-radius: 50%;
    }

    .service-center {
      display: none!important;
      width: 100%;
      @include flex-center;
      white-space: nowrap;
      color: white;
      background-color: $brandColor;
      height: 45px;
      border-radius: 4px;
      padding: 0 15px;
      //font-family: Medium;
      line-height: 14px;
      font-weight: bold;
      letter-spacing: 0.4px;
      margin-top: 20px;

      .service-center-icon {
        margin-right: 15px;

        path {
          fill: white;
        }
      }

      span {
        margin-top: 2px;
      }
    }

    .languages-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        width: 50%;
      }

      .language-button {
        width: 50%;
        height: 45px;
        background-color: white;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border-radius: 6px;
        margin: 0;
      }
    }

    .buttons-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 12px;

      .menu-button {
        width: 48%;
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .mobile-menu-overlay {
    background: black;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9000;
    opacity: 0.55;
  }

  & {
    .hide {
      display: none;
    }
  }
}

.ant-dropdown {
  z-index: 10003;
}

@media all and (max-width: 600px) {
  .mobile-menu-wrapper {
    padding-top: 40px;

    .mobile-menu-content {
      padding: 40px $mainPadding $mainPadding;
      width: 100%;

      .search_box {
        height: 36px;
        padding: 5px;

        .input-group-append {
          .search_btn {
            padding-right: 5px;

            svg {
              width: 20px;
            }
          }
        }

        .search {
          font-size: 13px;
        }
      }

      .ant-menu {
        margin-top: 5px;
      }
    }
  }
}