@import "./assets/styles/antd-styles-overrides";
@import "./assets/styles/mainVariables";
@import "./assets/styles/mixins";
@import "assets/styles/fonts";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  user-select: none;
  outline: 0;
}
html{
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: Regular, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //@extend .custom-scrollbar-y;
  height: 100%;
  width: 100% ;
  //background-color: #f7f7f7;
}

iframe{
  display: none;
}
button {
  cursor: pointer;
  border: none;
  padding: 0;

  &:focus {
    outline: none;
  }
}

p {
  margin-bottom: 0;
}

a, a:hover {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

input, select {
  border: none;
  &:focus {
    outline: none;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background-color: $inputBGColor !important;
  -webkit-box-shadow: 0 0 0 30px $inputBGColor inset !important;

}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}
.ReactModal__Body--open {
  //overflow: hidden;
  //padding-right: 17px;
}
//.ant-scrolling-effect{
//  width: 100% !important;
//}
.modal-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  width: auto;
  //border-radius: 4px;
  transform: translate(-50%, -50%);
  //background: white;
  z-index: 9900;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9900;
  bottom: 0;
  background: rgba(0, 0, 0, .35);
}

.separator {
  border-top: 1px solid $borderColor;
}

.privacy-policy-container{
  margin-top: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 $mainPadding 80px;
  h3{
    font-size: 30px;
    font-family: Bold;
  }
  p{
    text-align: left;
    h2{
      font-size: 22px;
      margin-top: 30px;
      font-family: Bold;
    }
    span{
      font-size: 18px;
      font-family: Regular;
    }
    ul{
      list-style: unset;
      li{
        font-size: 18px;
        font-family: Regular;
        margin-left: 20px;
      }
    }
  }
}